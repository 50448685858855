<template>
  <div class="dashboard">
    <template v-if="typeof isAdmin === 'boolean'">
      <Filters
        :load-on-init="!isAdmin"
        :loading="loading"
        :on-change="onChangeFilters"
        :use-categories="true"
        :use-intervals="true"
        @filterError="onErrorFilters"
      />
    </template>

    <Table v-if="Object.keys(tableData).length !== 0" :data="tableData"/>
    <NoDataFound v-else-if="Object.keys(tableData).length === 0 && !loading" />
  </div>
</template>

<script>
import ElasticAPI from "../../services/API/ElasticAPI";
import {showToastError} from "../../services/Helpers/HelperToast";
import fetchFilters from "../../mixins/fetchFilters";
import NoDataFound from "@/components/stats/NoDataFound";


export default {
  name: "ReportProducts",
  mixins: [fetchFilters],

  components: {
    Filters: () => import('../../components/filters/Filters'),
    Table: () => import('../../components/ReportProducts/Table'),
    NoDataFound
  },

  data() {
    return {
      tableData: {},
      rawData: [],
      isAdmin: false
    }
  },

  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
    this.loading = !this.isAdmin
  },

  methods: {
    searchMiddleware() {
      const isLicensesEmpty = !this.filters.licenses.length;
      const isRetailersEmpty = !this.filters.retailers.length;
      if (isLicensesEmpty || isRetailersEmpty) {
        if (isLicensesEmpty) {
          showToastError('Licenses must be not empty', this.$toast);
        }
        if (isRetailersEmpty) {
          showToastError('Retailers must be not empty', this.$toast);
        }
        return true
      }
      return false
    },

    async refreshData() {

      const isNeedToPrevent = this.searchMiddleware()
      if (isNeedToPrevent) return

      const promiseReportProducts = ElasticAPI.getReportProducts(this.filters);
      await promiseReportProducts.then(({rawData, tableData}) => {
        this.rawData = rawData;
        this.tableData = tableData;
      }).catch(error => {
        showToastError("Something went wrong", this.$toast);
        console.error(error)
      })
    }
  }
};
</script>
